<template>
  <div>
    <p v-if="!accessToken">logging in . . .</p>
    <p v-else>logged in</p>
  </div>
</template>

<script>
import { actionTypes } from "@/app/shared/state";
import { discordOauthUrl } from "@/environment/environment";

export default {
  name: "Login",
  data() {
    return {}
  },
  computed: {
    accessToken() {
      return this.$store.state.authData.discordAccessToken;
    }
  },
  created() {
    const params = new URLSearchParams(this.$route.hash);  // parse url hash as parameters
    const token = params.get("access_token");

    if (!token) {
      window.open(discordOauthUrl, "_self");
    }

    this.$store.dispatch(actionTypes.LOGIN, token)
    this.$router.push("/");
  }
}
</script>

<style scoped>

</style>
